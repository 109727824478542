
thead th.ascending::after {
    content: "->";
    transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    display: inline-block;
    margin-left: 1em;
}

thead th.descending::after {
    content: "<-";
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    display: inline-block;
    margin-left: 1em;
}

tbody td {
    padding: 0.5em;
    border-bottom: 1px solid #ccc;
}

thead th:hover {

    user-select: none;
}
