.pg-viewer-wrapper {
    /*overflow-y: unset !important;*/
    overflow-y: hidden;
}

.photo-viewer-container {
    width: unset !important;
    height: unset !important;
}

.photo-viewer-container > img {
    width: unset !important;
    height: unset !important;
}

.react-grid-Grid {
    height: 500px;
}
.pg-viewer-wrapper {
    margin: 20px;
}
.react-grid-Header {
    text-align: center;
    /*pointer-events: none;*/
}
.react-grid-HeaderCell-sortable {
    width: auto;
    word-break: break-all;
    pointer-events: none;

}
.react-grid-HeaderCell {
    width: auto;
    font-size: 12px;
    padding-left: 5px;
    padding-right: 10px;

}
.react-grid-Viewport {
    /*text-align: left;*/
    font-size: 11px;
    width: auto;
    padding-left: 5px;
    padding-right: 10px;

}
.react-grid-Canvas {

}

::-webkit-scrollbar-track
{
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
    /*background-color: #F5F5F5;*/
}
::-webkit-scrollbar
{
    /*width: 4px !important;*/
    /*background-color: #F5F5F5;*/
}

